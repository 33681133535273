$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";
$fa-font-path: "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
// @import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
// @import "../node_modules/leaflet/dist/leaflet.css"; 
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/material.css';


@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';







@-webkit-keyframes trans1{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 

    transform : translate3d(-250px,0,0);
  }
}
@-moz-keyframes trans1{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-250px,0,0);
  }
}
@keyframes trans1{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-250px,0,0);
  }
}

@-webkit-keyframes trans2{ 
  from { 
    transform : translate3d(-160px,0,0);
  }
  to { 
    transform : translate3d(53px,0,0);
  }
}
@-moz-keyframes trans2{ 
  from { 
    transform : translate3d(-160px,0,0);
  }
  to { 
    transform : translate3d(53px,0,0);
  }
}
@keyframes trans2{ 
  from { 
    transform : translate3d(-160px,0,0);
  }
  to { 
    transform : translate3d(53px,0,0);
  }
}

@-webkit-keyframes trans3{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-220px,0,0);
  }
}
@-moz-keyframes trans3{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-220px,0,0);
  }
}
@keyframes trans3{ 
  from { 
    transform : translate3d(53px,0,0);
  }
  to { 
    transform : translate3d(-220px,0,0);
  }
}

.loader-outer {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 9999;
    background: #fff;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.5s, opacity 0.3s linear;
    -moz-transition: visibility 0.5s, opacity 0.3s linear;
    transition: visibility 0.5s, opacity 0.3s linear;
    &.hide{
        visibility: hidden;
        opacity: 0; 
    }
    .loader-container {
        height:100%;
        width:100%;
        display:table;
        .loader-inner {
            vertical-align:middle;
            height:100%;
            display:table-cell;
            .loader{
                width:80px;
                margin:auto;
                position:relative;
                height:60px;
                .mask{
                    position:absolute;  
                    overflow:hidden;
                    -webkit-border-radius: 5px; 
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    .plane{
                        background:#20a8d8;
                        width:400%;
                        height:100%;
                        position:absolute;
                    } 
                }
                #top{
                    width:53px;
                    height:15px;
                    left:20px;
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0); 
                    .plane{
                        -webkit-animation : trans1 1.3s ease-in infinite  0s backwards;
                        -moz-animation : trans1 1.3s ease-in infinite  0s backwards;
                        animation : trans1 1.3s ease-in infinite  0s backwards;
                    }
                }
                #middle{
                    width:33px;
                    height:15px;
                    left:20px;
                    top:15px; 
                    -webkit-transform: skew(-15deg, 40deg);
                    -moz-transform: skew(-15deg, 40deg); 
                    transform: skew(-15deg, 40deg);
                    .plane{
                        transform : translate3d(0px,0,0);  
                        background : #20a8d8;
                        -webkit-animation : trans2 1.3s linear infinite  0.3s  backwards;
                        -moz-animation : trans2 1.3s linear infinite  0.3s  backwards;
                        animation : trans2 1.3s linear infinite  0.3s  backwards;
                    }
                }
                #bottom{
                    width:53px;
                    height:15px;
                    top:30px;  
                    -webkit-transform: skew(-15deg, 0);
                    -moz-transform: skew(-15deg, 0);
                    transform: skew(-15deg, 0);
                    .plane{
                        -webkit-animation : trans3 1.3s ease-out infinite  0.7s backwards;
                        -moz-animation : trans3 1.3s ease-out infinite  0.7s backwards;
                        animation : trans3 1.3s ease-out infinite  0.7s backwards;
                    }
                }
                p.loader-text{
                    color:#000;
                    position:absolute;
                    top:42px;
                    font-family: 'MoonHouse';
                    font-size:16px;
                    span{
                        color: #20a8d8;
                    }
                }
            }      
        }           
    }
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #cccccc;
    }
    &:active{
        background: #888888; 
    }
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #666666;
    }
    &:active{
        background: #333333; 
    }
}
::-webkit-scrollbar-corner {
    background: transparent;
}

//--Manual changes

///--- Card Css
.card{
  height: 100%;
  width: 100%;
}

///-- Input fields Danger Success

.step{
  .step-content{
      background: #b9b9b9;
      height: 40px;
      font-size: 1.5rem;
      cursor: pointer;
      .step-name{
          font-size: 14px;
      }
      &.confirmed{
          background-color: #4BAE4F !important;
      }
  }
}

.confirmed-content{
  .fa-check{
      font-size: 120px;        
  }
  .fa-print{
      font-size: 24px;
  }
}

///-- File Upload

.file-upload{
  .file-upload-btn{
      position: absolute;
      width: 100%;
      height: 35px;
      z-index: 9;
      opacity: 0;
  }
  .delete-file{
      position: absolute;
      right: 50px;
      top: 8px;
      font-size: 16px;
      opacity: .8;
      cursor: pointer;
      z-index: 99;
      visibility: hidden;
  }
  &:hover .delete-file{
      visibility: visible;
  }
}


@import "../node_modules/@syncfusion/ej2/material.css";

.e-grid .e-dialog.e-checkboxfilter, .e-grid .e-dialog.e-flmenu {
  border-radius: 10px;
  max-width: 200px;
}
.e-dialog .e-dlg-content {
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 0;
}
.e-dialog .e-footer-content {
  border-radius: 10px;
  padding: 0;
}


///-- Image upload


.avatar-upload {
  position: relative;
  max-width: 120px;
  .avatar-edit {
      position: absolute;
      right: 12px;
      z-index: 1;
      top: 10px;
      input {
          display: none;
          + label {
              display: inline-block;
              width: 34px;
              height: 34px;
              margin-bottom: 0;
              position: absolute;
              border-radius: 100%;
              background: #FFFFFF;
              border: 1px solid transparent;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
              cursor: pointer;
              font-weight: normal;
              transition: all .2s ease-in-out;
              &:hover {
                  background: #f1f1f1;
                  border-color: #d6d6d6;
              }
              &:after {
                  content: "\f040";
                  font-family: 'FontAwesome';
                  color: #757575;
                  position: absolute;
                  top: 10px;
                  left: 0;
                  right: 0;
                  text-align: center;
                  margin: auto;
              }
          }
      }
  }
  .avatar-preview {
      width: 120px;
      height: 120px;
      position: relative;
      border: 6px solid #F8F8F8;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      > div {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
      }
  }
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
  padding: 0;
}
.app-footer {
  height: auto !important;
}
// .e-grid .e-gridheader .e-headercell, .e-grid .e-gridheader .e-detailheadercell {
//   background-color: red !important;
// }
.main-content{
  padding: 5px;
}
.app.combined .dropdown-item.active, .app.combined .dropdown-item:active {
background-color: white;
color: black;
}

.la-ball-clip-rotate-multiple,
.la-ball-clip-rotate-multiple > div {
position: relative;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}
.la-ball-clip-rotate-multiple {
display: block;
font-size: 0;
color: rgb(0, 172, 193) !important
}
.la-ball-clip-rotate-multiple.la-dark {
color: rgb(0, 172, 193) !important
}
.la-ball-clip-rotate-multiple > div {
display: inline-block;
float: none;
background-color: currentColor;
border: 0 solid currentColor;
}
.la-ball-clip-rotate-multiple {
width: 32px;
height: 32px;
}
.la-ball-clip-rotate-multiple > div {
position: absolute;
top: 50%;
left: 50%;
background: transparent;
border-style: solid;
border-width: 2px;
border-radius: 100%;
-webkit-animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
-moz-animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
-o-animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
animation: ball-clip-rotate-multiple-rotate 1s ease-in-out infinite;
}
.la-ball-clip-rotate-multiple > div:first-child {
position: absolute;
width: 32px;
height: 32px;
border-right-color: transparent;
border-left-color: transparent;
}
.la-ball-clip-rotate-multiple > div:last-child {
width: 16px;
height: 16px;
border-top-color: transparent;
border-bottom-color: transparent;
-webkit-animation-duration: 0.5s;
-moz-animation-duration: 0.5s;
-o-animation-duration: 0.5s;
animation-duration: 0.5s;
-webkit-animation-direction: reverse;
-moz-animation-direction: reverse;
-o-animation-direction: reverse;
animation-direction: reverse;
}  .la-ball-clip-rotate-multiple.la-sm {
width: 16px;
height: 16px;
}
.la-ball-clip-rotate-multiple.la-sm > div {
border-width: 1px;
}
.la-ball-clip-rotate-multiple.la-sm > div:first-child {
width: 16px;
height: 16px;
}
.la-ball-clip-rotate-multiple.la-sm > div:last-child {
width: 8px;
height: 8px;
}
.la-ball-clip-rotate-multiple.la-2x {
width: 64px;
height: 64px;
}
.la-ball-clip-rotate-multiple.la-2x > div {
border-width: 4px;
}
.la-ball-clip-rotate-multiple.la-2x > div:first-child {
width: 64px;
height: 64px;
}
.la-ball-clip-rotate-multiple.la-2x > div:last-child {
width: 32px;
height: 32px;
}
.la-ball-clip-rotate-multiple.la-3x {
width: 96px;
height: 96px;
}
.la-ball-clip-rotate-multiple.la-3x > div {
border-width: 6px;
}
.la-ball-clip-rotate-multiple.la-3x > div:first-child {
width: 96px;
height: 96px;
}
.la-ball-clip-rotate-multiple.la-3x > div:last-child {
width: 48px;
height: 48px;
}
.ng-select.ng-select-single .ng-select-container {
height: 27px !important;
}
.ng-select .ng-select-container {
color: #333;
border-radius: 4px;
border: 1px solid #ccc;
min-height: 27px !important;
-webkit-box-align: center;
align-items: center;
}
.pdfViewer.removePageBorders .page {
margin: 0 auto 10px 0px !important;
border: none;
}

@-webkit-keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-moz-keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -moz-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-o-keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -o-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes ball-clip-rotate-multiple-rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    -moz-transform: translate(-50%, -50%) rotate(180deg);
    -o-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem !important;
  pointer-events: all;
  border-radius: 0.5rem;
  background-color: #004290 !important;
}

.e-switch-wrapper.bar-color .e-switch-inner,
.e-switch-wrapper.bar-color .e-switch-off {
  background-color: #004290 !important;
  color: #fff !important;
  border-color: #004290 !important;
}
.e-switch-wrapper.bar-color .e-switch-inner.e-switch-active,
.e-switch-wrapper.bar-color:hover .e-switch-inner.e-switch-active .e-switch-on {
  background-color:#004290  !important ;
  color: #fff !important;
  border-color: #004290 !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}
.Scrolling{
  height: 200px;
  overflow-y: auto;
}


.btn-circle.btn-xl {
width: 29px;
height: 27px;
padding: 10px 16px;
border-radius: 35px;
font-size: 24px;
line-height: 1.33;
}

.e-switch-wrapper.bar-color .e-switch-handle {
  background-color: #fff !important;
  color: #fff !important;
  border-color: #004290 !important;
}
.e-switch-wrapper .e-switch-on, .e-css.e-switch-wrapper .e-switch-on {
  background-color: #004290 !important;
  color: #fff !important;
  border-color: #004290 !important;
}